/* eslint-disable no-console */
import React, { useReducer, useEffect } from 'react';
import { AuthContext } from './auth.context';

import { authRef } from '@configs/firebase';
import { getGrantedCompanyById } from 'services/companiesServices';
// import { getUser } from 'services/userServices';

const INITIAL_STATE = {
  currentForm: 'signIn',

  authStatusReported: false,
  isAuthenticated: false,
  displayName: null,
  user: null,
  appRols: null,
  enterpriseRols: null,
  photoURL: null,
  email: null,
  uid: null,
  company: null,
};

function reducer(state, action) {
  console.log('Auth Provider - Starting reducer with action:', action.type);

  switch (action.type) {
    case 'SIGNIN':
      return {
        ...state,
        currentForm: 'signIn',
      };

    case 'INIT_EMPTY_AUTH':
      return {
        ...state,
        ...action.payload,
      };

    case 'INIT_AUTH':
      return {
        ...state,
        ...action.payload,
      };
    case 'SIGNIN_SUCCESS': {
      const user = action.payload;
      return user.getIdTokenResult().then((idTokenResult) => {
        const { appRols, enterpriseRols } = idTokenResult.claims;

        console.log('idTokenResult.claims:', idTokenResult.claims);

        return {
          ...state,
          ...{
            isAuthenticated: user && user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email,
            uid: user.uid,
            user,
            appRols,
            enterpriseRols,
          },
        };
      });
    }
    case 'SIGNUP_SUCCESS': {
      const user = action.payload;
      return user.getIdTokenResult().then((idTokenResult) => {
        const { appRols, enterpriseRols } = idTokenResult.claims;

        return {
          ...state,
          ...{
            isAuthenticated: user && user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email,
            uid: user.uid,
            user,
            appRols,
            enterpriseRols,
          },
        };
      });
    }

    case 'SIGN_OUT':
      return {
        ...state,
        ...{
          isAuthenticated: false,
          displayName: '',
          email: null,
          user: null,
          appRols: null,
          enterpriseRols: null,
        },
      };
    case 'SIGNUP':
      return {
        ...state,
        currentForm: 'signUp',
      };
    case 'FORGOTPASS':
      return {
        ...state,
        currentForm: 'forgotPass',
      };
    case 'UPDATE_COMPANY':
      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
}

function onAuthStateChange(callback) {
  authRef.onAuthStateChanged((user) => {
    if (user) {
      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          return idTokenResult;
        })
        .then((idTokenResult) => {
          return new Promise((resolve) => {
            resolve({ idTokenResult, userData: null });

            // getUser({ userId: user.uid, userToken: idTokenResult.token })
            //   .then((userData) => {
            //     return resolve({ userData, idTokenResult });
            //   })
            //   .catch((e) => {
            //     return reject(e);
            //   });
          });
        })
        .then(({ userData, idTokenResult }) => {
          console.log('onAuthStateChange:', idTokenResult);
          const { appRols, enterpriseRols } = idTokenResult.claims;

          return callback({
            payload: {
              authStatusReported: true,
              isAuthenticated: !!(user && user.email),
              displayName: user.displayName,
              email: user.email,
              user,
              appRols,
              enterpriseRols,
              photoURL: user.photoURL,
              userData,
              uid: user.uid,
            },
            type: 'INIT_AUTH',
          });
        })
        .catch((e) => {
          throw e;
        });
    } else {
      callback({
        payload: {
          authStatusReported: true,
          isAuthenticated: false,
          user: null,
          displayName: null,
          appRols: null,
          enterpriseRols: null,
          photoURL: null,
        },
        type: 'INIT_EMPTY_AUTH',
      });
    }
  });
}

// export const AuthProvider: React.FunctionComponent<any> = ({ children }) => {
export const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);

  console.log('Auth Provider - Current authState:', {
    isAuthenticated: authState.isAuthenticated,
    authStatusReported: authState.authStatusReported,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChange(function (args) {
      console.log('Auth Provider - Auth state changed:', args.type);
      authDispatch(args);
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      if (authState.isAuthenticated && authState.enterpriseRols?.length > 0) {
        try {
          const companyId = authState.enterpriseRols[0].companyId;
          const companyResponse = await getGrantedCompanyById({
            id: companyId,
            filters: [{ key: 'state', value: 1, operator: '$equal' }],
          });

          authDispatch({ type: 'UPDATE_COMPANY', payload: companyResponse });
        } catch (error) {
          console.error('Error fetching company:', error);
        }
      }
    };

    fetchCompanyInfo();
  }, [authState.isAuthenticated, authState.enterpriseRols]);

  if (!authState) return <></>;

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {authState.authStatusReported ? (
        children
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </AuthContext.Provider>
  );
};
