// import * as firebase from "firebase";
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';

import { getFirestore } from 'firebase/firestore';

import { FirebaseConfig } from './firebaseConfig';

// Remove storageBucket from the config before initializing
const { storageBucket, ...configWithoutStorage } = FirebaseConfig;

const app = initializeApp(configWithoutStorage);

const dbAux = getFirestore(app);
const authAux = getAuth();

export const db = dbAux;
export const authRef = authAux;
export const auth = getAuth;
