// eslint-disable-next-line prefer-template

import { authRef } from '@configs/firebase';
import Swal from 'sweetalert2';
import Spinner from '@components/spinner/Fallback-spinner-small';
import ReactDOMServer from 'react-dom/server';

const monthShortNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const monthLongNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const dayOfWeekShortNames = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];

export const FORM_OPTIONS_PRELOAD_SUFFIX = 'PreloadOptions';

export const getUserWithToken = function () {
  return new Promise((resolve, reject) => {
    authRef.onAuthStateChanged((user) => {
      if (!user) resolve(null);
      else
        user
          .getIdToken()
          .then((token) => {
            resolve({ token, user });
          })
          .catch((e) => {
            reject(e);
          });
    });
  });
};

export const createNestedObject = function (base, names, value) {
  const namesAux = [...names];

  // If a value is given, remove the last name and keep it for later:
  const lastName = arguments.length === 3 ? namesAux.pop() : false;

  // Walk the hierarchy, creating new objects where needed.
  // If the lastName was removed, then the last object is not set yet:
  for (let i = 0; i < namesAux.length; i++) {
    base = base[namesAux[i]] = base[namesAux[i]] || {};
  }

  // If a value was given, set it to the last name:
  if (lastName) base = base[lastName] = value;

  // Return the last object in the hierarchy:
  return base;
};

export const toDateObject = function (dirtyDate) {
  if (!dirtyDate) return null;

  if (dirtyDate instanceof Date && !isNaN(dirtyDate.getTime())) return dirtyDate;

  if (dirtyDate._seconds) return new Date(dirtyDate._seconds * 1000);
  else if (typeof dirtyDate === 'string' || typeof dirtyDate === 'number')
    return new Date(dirtyDate);

  // .toLocaleDateString(
  //     'en-ES'
  //   );
  // const createdAtString = new Date(userTask.createdAt._seconds * 1000).toLocaleDateString(
  //   'en-ES'
  // );
};

export const dateToUTC = function (date) {
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
};

export const dateToShortString = function (date) {
  if (!date) return '';

  return date.toLocaleDateString('en-GB');
};

export const parseDateToShortString = function (date) {
  const dateObj = toDateObject(date);

  if (!dateObj) return '';

  return dateToShortString(dateObj);
};

export const parseDateToDateTimeString = function (date) {
  const dateObj = toDateObject(date);

  if (!dateObj) return '';

  const dateString = dateToShortString(dateObj);

  return `${dateString} ${dateObj.getHours().toString().padStart(2, '0')}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const getUTCDayOfWeekShortName = function (date) {
  return dayOfWeekShortNames[date.getUTCDay()];
};

export const getDayOfWeekShortName = function (date) {
  return dayOfWeekShortNames[date.getDay()];
};

export const getMonthShortName = function (date) {
  return monthShortNames[date.getUTCMonth()];
};

export const getMonthLongName = function (date) {
  return monthLongNames[date.getUTCMonth()];
};

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`;
  }
  return 'just now';
  // return `${Math.floor(seconds)} seconds`;
};

export const MULTIPLE_RELATIONSHIP_SUFFIX = '_SOURCE_ENTITIES';

// eg: obj: { userId: 'bla', otherProps: '', userId_SOURCE_ENTITIES: [{ id: 'bla' } ]}, key: 'userId'
export const getSourceEntityData = function ({ obj, key }) {
  if (!obj) return null;

  const objPropValue = obj[key];

  if (!objPropValue) return null;

  const objPropList = obj[key + MULTIPLE_RELATIONSHIP_SUFFIX];

  if (!objPropList) return null;

  return objPropList.find((item) => {
    return item.id === objPropValue;
  });
};

export const getErrorData = (error) => {
  let code = null;
  let message = error.message;

  if (error.isAxiosError && error.response && error.response.data && error.response.data.message)
    message = error.response.data.message;

  if (error.isAxiosError && error.response && error.response.data && error.response.data.code)
    code = error.response.data.code;

  if (!code && error.isAxiosError && error.response && error.response.status)
    code = `HTTP${error.response.status}`;

  return { code, message };
};

export const handleError = function (error) {
  // eslint-disable-next-line no-console
  console.error(error);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: 'button button-success',
      cancelButton: 'button button-error',
    },
    buttonsStyling: false,
  });

  const { code, message } = getErrorData(error);

  let title = 'Error';

  if (code) title = `Error (HTTP${code})`;

  newSwal.fire(title, message, 'error');
};

export const BACK_ROUTE_KEYWORD = 'back';

export const resolveWithConfirmation = ({
  title,
  message,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  return new Promise((resolve, reject) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title,
        text: message,
        showCancelButton: true,
        confirmButtonText: confirmButtonLabel,
        cancelButtonText: cancelButtonLabel,
        reverseButtons: true,
        // allowOutsideClick: () => !Swal.isLoading(),
        allowOutsideClick: false,
      })
      .then((result) => {
        const confirmed = result && result.isConfirmed;
        Swal.close();
        resolve({ confirmed });
      })

      .catch((e) => {
        reject(e);
      });
  });
};

const spinnerHtml = ReactDOMServer.renderToString(<Spinner />);

export const showCustomLoading = () => {
  return new Promise((resolve, reject) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      showConfirmButton: false,
      buttonsStyling: false,
    });

    newSwal.fire({
      title: '',
      allowEscapeKey: false,
      allowOutsideClick: false,
      html: spinnerHtml,
      didOpen: () => {
        resolve();
      },
    });
  });
};

export const closeCustomLoading = () => {
  Swal.close();
};

export const enumValuesToArray = (enumType) => {
  return Object.keys(enumType).filter((item) => {
    return isNaN(Number(item));
  });
};

export const getFirstNameFromString = (str) => {
  if (!str || !str.length) return '';

  return str.split(' ')[0].trimEnd();
};

export const formatMoney = function (amountArg, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    let amount = amountArg;
    if (!isFinite(amount)) amount = 0;

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const ii = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const jj = ii.length > 3 ? ii.length % 3 : 0;

    return (
      negativeSign +
      (jj ? ii.substring(0, jj) + thousands : '') +
      ii.substring(jj).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - ii)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    throw e;
  }
};

export const usdValue = () => {
  return 1200;
};

export const formatMoneyWithCurrency = function (
  amountArg,
  decimalCount = null,
  thousands = '.',
  decimal = ',',
  currency = 'ars'
) {
  try {
    let currencySign = '';
    let amount = amountArg;

    // Determine default decimal count based on currency if not provided
    if (decimalCount === null) {
      const upperCurrency = currency.toUpperCase();
      if (upperCurrency === 'ARS') {
        decimalCount = 0;
      } else if (['USD', 'USDM', 'USDT', 'USDC'].includes(upperCurrency)) {
        decimalCount = 2;
      } else if (['BTC', 'WBTC', 'ETH', 'WETH'].includes(upperCurrency)) {
        decimalCount = 4;
      } else {
        decimalCount = 0; // Default fallback
      }
    }

    if (!isFinite(amount)) amount = 0;

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const isNegative = amount < 0;
    const absAmount = Math.abs(amount);

    const ii = parseInt(absAmount.toFixed(decimalCount)).toString();
    const jj = ii.length > 3 ? ii.length % 3 : 0;

    // Convert currency to uppercase for consistency
    const upperCurrency = currency.toUpperCase();
    if (['ARS', 'USD', 'USDM', 'USDT', 'USDC', 'BTC', 'ETH', 'WETH'].includes(upperCurrency)) {
      currencySign = upperCurrency;
    } else if (currency === 'WBTC') {
      currencySign = 'BTC';
    } else {
      currencySign = upperCurrency; // Use the uppercase currency instead of throwing error
    }

    return (
      currencySign +
      (isNegative ? ' -' : ' ') +
      (jj ? ii.substring(0, jj) + thousands : '') +
      ii.substring(jj).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal + (absAmount - parseInt(absAmount)).toFixed(decimalCount).slice(2)
        : '')
    );
  } catch (e) {
    throw e;
  }
};

export const shortenContractAddress = (address) => {
  if (!address) return address;
  return address.substr(address.length - 4);
};

export const getDisplayTokenSymbol = (symbol) => {
  const tokenSymbolMap = {
    WBTC: 'BTC',
    // Add more mappings here as needed
  };

  return tokenSymbolMap[symbol] || symbol;
};

export const formatAddress = (address, leading = 0, trailing = 4) => {
  if (!address) return '';
  if (leading === 0) return `#...${address.slice(-trailing)}`;
  return `${address.slice(0, leading)}...${address.slice(-trailing)}`;
};
