export const getDomainText = (key) => {
  const hostname = window.location.hostname;

  const domainConfig = {
    Aconcagua: {
      companyName: 'Aconcagua',
      companySlogan: 'Reaching New Heights',
      companyURL: 'https://aconcagua.finance',
      loginImageLight: 'registro-v3.png',
      loginImageDark: 'registro-v3.png',
      logoSourceKey: 'Aconcagua',
      logoPathKey: 'Aconcagua',
      scssPathKey: 'Aconcagua',
      welcome1: 'Ingresá a Aconcagua',
      welcome2: '',
      olvidaste: '¿Olvidaste tu contraseña?',
      olvidaste2: '¿No tenés tu contraseña?',
      olvidaste3: 'Ingresá tu email y te enviaremos las instrucciones para recuperarla',
      register1: 'Registrate en Aconcagua',
      registerImageLight: 'forgot-password-v2.svg',
      registerImageDark: 'forgot-password-v2-dark.svg',
    },
    Boveda: {
      companyName: 'Bóveda',
      companySlogan: 'Climbing Together',
      companyURL: 'https://boveda.ai',
      loginImageLight: 'forgot-password-v2.svg',
      loginImageDark: 'forgot-password-v2-dark.svg',
      logoSourceKey: 'Boveda',
      logoPathKey: 'Boveda',
      scssPathKey: 'Boveda',
      welcome1: 'Ingresá a tu Bóveda',
      welcome2: '',
      olvidaste: '¿Olvidaste tu contraseña?',
      olvidaste2: '¿No tenés tu contraseña?',
      olvidaste3: 'Ingresá tu email y te enviaremos las instrucciones para crearla',
      register1: 'Registrate en Bóveda',
      registerImageLight: 'forgot-password-v2.svg',
      registerImageDark: 'forgot-password-v2-dark.svg',
    },
  };

  const aconcaguaDomains = [
    'aconcagua.app',
    'aconcaguapsav.web.app',
    'catedral-fb.web.app',
    'use-lanin.web.app',
  ];
  const bovedaDomains = [
    'app.boveda.ai',
    'boveda.ai',
    'boveda.web.app',
    'catedral-boveda.web.app',
    'lanin-boveda.web.app',
    'localhost',
  ];

  if (aconcaguaDomains.includes(hostname)) {
    return domainConfig.Aconcagua[key] || '';
  }

  if (bovedaDomains.includes(hostname)) {
    return domainConfig.Boveda[key] || '';
  }

  return '';
};

// Define all possible logo paths
export const logoPaths = {
  Aconcagua: require('@src/assets/images/logo/logo.png'),
  Boveda: require('@src/assets/images/logo/logo_boveda.png'),
  Local: require('@src/assets/images/logo/logo_boveda.png'),
};

export const spinnerPaths = {
  Aconcagua: require('@src/assets/images/logo/spinner.gif'),
  Boveda: require('@src/assets/images/logo/spinner_boveda.gif'),
  Local: require('@src/assets/images/logo/spinner_boveda.gif'),
};

export const smallSpinnerPaths = {
  Aconcagua: require('@src/assets/images/logo/spinner.gif'),
  Boveda: require('@src/assets/images/logo/spinner_boveda.gif'),
  Local: require('@src/assets/images/logo/spinner_boveda.gif'),
};
